
@mixin datepickerPosition($position: relative) {
  bottom: auto !important;
  box-shadow: 0 0 8px 0 rgba($color-black, 0.12) !important;
  left: auto !important;
  margin: $spacing-8 0 !important;
  position: $position !important;
  right: auto !important;
  top: calc(100% + 5px) !important;
}

@mixin label {
  font-size: 12px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
}

@mixin indicator {
  background-color: $color-gray;
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin-left: 44%;
  margin-top: $spacing-5;
}

@mixin unselectableStyle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}